@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Light.eot');
	src: url('../fonts/FuturaPT-Light.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT-Light.woff2') format('woff2'),
		url('../fonts/FuturaPT-Light.ttf') format('truetype'),
		url('../fonts/FuturaPT-Light.svg#FuturaPT-Light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Futura PT';
	src: url('../fonts/FuturaPT-Book.eot');
	src: url('../fonts/FuturaPT-Book.eot?#iefix') format('embedded-opentype'),
		url('../fonts/FuturaPT-Book.woff2') format('woff2'),
		url('../fonts/FuturaPT-Book.ttf') format('truetype'),
		url('../fonts/FuturaPT-Book.svg#FuturaPT-Book') format('svg');
	font-weight: 500;
	font-style: normal;
}